<template>
  <div class="home">
    <x-header>语音听写</x-header>

    <x-content
      :contentStyle="{
        display: 'block',
        width: 'calc(100% - 32px)',
        padding: '16px',
      }"
    >
      <x-card>
        <el-row :gutter="20" class="speechRec">
          <div class="uploadText" >
                <el-row :gutter="30" >
                  <el-col :span="4">
                    <img  src="/static/img/speechRecognition_img.png" class="icon">
                  </el-col>
                  <el-col :span="20" class="textData">
                  <h3>语音听写
                    <span class="tags">
                      <el-tag size="small">高准确率</el-tag>
                      <el-tag size="small">多语种</el-tag>
                      <el-tag size="small">毫秒级</el-tag>
                      </span>
                  </h3>
                  <p class="desc">
                    用于1分钟内的即时语音转文字技术，实时返回识别结果，达到一边上传音频一边获得识别文本的效果。
                  </p>
                  </el-col>  
                </el-row>
              </div>
        <p  class="optionData">
         <span style="display: block;" v-if="isReceivingVoice"> <img style="height: 50px;margin-bottom: 10px;" src="/static/img/yy.gif"/></span>
         <span class="uploadPic" v-else> <img src="/static/img/speechRecognition_icon.png" ></span>

          <el-button type="primary" v-if="!isReceivingVoice" @click="speechSynthesis"><i class="el-icon-microphone"></i> 语音输入</el-button>
          <el-button type="success" v-else @click="closeConnectIAT"><i class="el-icon-turn-off-microphone"></i> 语音关闭</el-button>
          <span class="tip_text">请录入1分钟内的语音</span>
        </p>
        <template v-if="isReceivingVoice">
        <h3>识别结果</h3>
        <p class="speechResult" > <el-button
                        size="mini"
                        @click="copySpeechResult()"
                        round
                        type="success"
                        plain
                        icon="el-icon-document-copy"
                        >&nbsp;复制</el-button
                      ><span style="margin-left: 10px">{{ speechResult }}</span></p>
                      </template>
        </el-row>
      </x-card>
    </x-content>
  </div>
</template>
<script>
import XCard from "../../components/busiess/XCard.vue";
import Bus from "../../util/bus";
import { connectIAT, disconnectIAT } from "../../util/IFYRecogning";
import { copyText } from "@/util/copyText";
export default {
  components: { XCard },
  name: "ApplyUseManager",
  data() {
    return {
      speechResult: "",
      isReceivingVoice: false,
    };
  },
  filters: {},
  created() {},
  computed: {},
  mounted() {
  },
  methods: {

    speechSynthesis() {
      Bus.on("/ify/recogning/error", () => {
        this.$message.error("无法录音，未找到可用麦克风");
        this.closeConnectIAT();
        return;
      });
      connectIAT();
      this.isReceivingVoice = true;
      this.speechResult = "";
      Bus.on("/ify/recogn", (options) => {
        this.speechResult = options.result;
        console.log(this.speechResult)
        if (options.isLast) {
          this.closeConnectIAT();
        }
      });
    },
    closeConnectIAT() {
      this.isReceivingVoice = false;
      disconnectIAT();
    },
    copySpeechResult() {
      if (!this.speechResult) {
        this.$message.warning("暂无数据，无法复制");
      } else {
        copyText(this.speechResult, this);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~katex/dist/katex.min.css";

.home {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
<style >
.speechRec {
  padding: 20px;
  margin: 10vh auto !important;
  width: 800px;

}
.optionData{
  text-align: center;
  background-image: linear-gradient(to top, #5c93ff14,#fff);
  background-color: #fff;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    padding: 20PX 0px;
}
.speechRec .uploadText{
  width: 800px;
  margin: 0 auto !important;
}
.speechRec .uploadText .icon{
  width: 100%;

}
.speechRec .uploadText .textData{
  text-align: left;
  margin-bottom: 15px;
}
.speechRec .uploadText h3{
  font-size: 20px;
    color: #1441c2;
    font-weight: bold;
    margin-bottom: 10px;
    padding-bottom: none;
    border-bottom: none;
}
.speechRec .uploadText .tags{
  margin-left: 20px;
}
.speechRec .uploadText .tags .base-tag{
  margin-right: 5px;

}
.speechRec .uploadText .desc{
line-height: 28px;
}
.speechRec .speechResult {
  line-height: 30px;
  word-break: break-all;
}
.speechRec h3 {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-weight: bold;
}
.uploadPic{
  display: block;
  margin: 10px 0px;
}
.uploadPic img{
  width: 80px;
}
.speechRec .tip_text{
  display: block;
  color: #606266;
    font-size: 14px;
    margin: 15px 0px;
}
</style>